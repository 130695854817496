.template {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.template > div:nth-child(1) {
  margin-top: 0px;
  max-height: 120px;
  flex: 0.43;
  display: flex;
}

.template > div:nth-child(2) > div:nth-child(1),
.template > div:nth-child(3) > div:nth-child(1),
.template > div:nth-child(4) > div:nth-child(1) {
  margin-right: 5px;
}

.template > div:nth-child(2) > div:nth-child(2),
.template > div:nth-child(3) > div:nth-child(2),
.template > div:nth-child(4) > div:nth-child(2) {
  margin-left: 5px;
}

.template > div {
  margin-bottom: 10px;
  /* flex: 0.5; */
  display: flex;
  flex: 1;
}

.template > div:nth-child(4) {
  margin-bottom: 0px;
}

.template > div:nth-child(1) > .textCard {
  background-color: #fff;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 8px;
  border: 1px solid #a8a8a8;
  padding: 5px 10px;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.template > div:nth-child(1) > .textCard:nth-last-child(1) {
  margin-right: 0px;
}

.textCard > .title {
  flex: 0.5;
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .textCard > .title{
    font-size: 14px;
  }
}C

.textCard > .innerCard {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.textCard > .innerCard > div {
  justify-content: center;
  flex: 2;
  display: flex;
  align-items: center;
}

.textCard > .innerCard > div > .amount {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  line-height: 40px;
  font-size: 32px;
  font-weight: 400;
}

.textCard > .innerCard > div > .unit {
  font-size: 24px;
  font-weight: 500;
}

.chartCard {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 0 0 8px 8px;
}

.chartCard > .title {
  border: 1px solid #a8a8a8;
  border-bottom: none;
  height: 32px;
  font-size: 24px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .chartCard > .title {
    font-size: 14px;
  }
}

.chartCard > .title > .subTitle {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .chartCard > .title > .subTitle {
    font-size: 14px;
  }
}

.chartCard > .content {
  border-radius: 0 0 8px 8px;
  border: 1px solid #a8a8a8;
  flex: 1;
  display: flex;
}

.donutDiv {
  display: flex;
  flex: 1;
}

.donutDiv > div:nth-child(1) {
  display: flex;
  flex: 2;
  justify-content: center;
}

.donutDiv > div:nth-child(2) {
  display: flex;
  flex: 1.5;
  flex-direction: column;
  font-size: 20px;
  border-left: 1px solid #a8a8a8;
}

.donutDiv > div:nth-child(2) > .title {
  flex: 0.2;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
.donutDiv > div:nth-child(2) > .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.donutDiv > div:nth-child(2) > .container > .subTitle {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}


