.searchContainer {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.search {
  flex: 11;
}

.searchExtra {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.searchExtra .initBtn {
  width: 120px;
}

.searchBtn {
  margin-right: 8px;
  margin-top: 20px;
  width: 240px;
}

.search > div:not(.period) {
  display: flex;
}

.period {
  display: flex;
  margin: 5px 0px;
}

.periodDash {
  display: inline-block;
  width: 28px;
  text-align: center;
}

.searchText {
  display: inline-block;
  width: 140px;
  text-align: right;
}

.searchDash {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.searchSelector {
  width: 280px;
}

.searchInput {
  width: 360px;
}

.divider {
  border: none;
}

.TimePicker {
  margin-left: 10px;
}

div.buttons > button:nth-child(2) {
  margin-left: 10px;
}
