.templete {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.info > .title {
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.info > .title > .innerTitle {
  border-bottom: 1px solid #000;
  padding-bottom: 8px;
}

.info > .title > .innerTitle > .innerText {
  border-bottom: 1px solid #000;
  display: flex;
}

.info > .title > .innerTitle > .innerText > div {
  font-size: 24px;
  display: flex;
  align-items: flex-end;
}

.productContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  font-size: 24px;
}

.productContainer > div > ul > li {
  border-bottom: 1px solid #000;
  list-style: none;
  display: flex;
  align-items: center;
  height: 40px;
}

.productContainer > div > ul > li > .title {
  width: 180px;
}

.productContainer > div > ul > li > div > span,
.productContainer > div > ul > li > span {
  font-size: 14px;

  font-weight: 700;
}

.imgBox {
  border: 1px solid #808080;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.imgBox:nth-child(1) {
  margin-right: 20px;
}

.content {
  margin-bottom: 20px;
}

.content > .title {
  color: #000;
  font-size: 24px;
  margin: 10px 0px;
}
