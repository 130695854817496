@value desktopLargeMediaQuery from '../../common/size.css';

.div5 {
  height: 5px !important;
}

.template {
  display: flex;
  height: 100%;
}

.template > .leftContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.template > .leftContainer > .div1 {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 4px 4px 8px 8px;
  border: 1px solid #a8a8a8;
  background: #fff;
}

.template > .leftContainer > .div1 > .chart {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template > .leftContainer > .div2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.template > .leftContainer > .div2 > .inner {
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 4px 4px 8px 8px;
  border: 1px solid #a8a8a8;
  background: #fff;
}

.template > .leftContainer > .div2 > .inner > .numberCardContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.template > .leftContainer > .div2 > .inner {
  flex: 1;
  flex-direction: column;
  display: flex;
}

.template > .leftContainer > .div3 {
  flex: 1.5;
  display: flex;
  flex-direction: column;
}

.template > .leftContainer > .div3 > .chartContainer {
  display: flex;
  flex: 1;
}

.template > .leftContainer > .div3 > .chartContainer > div {
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 4px 4px 8px 8px;
  border: 1px solid #a8a8a8;
  flex: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.template > .leftContainer > .div3 > .chartContainer > div > .chart {
  border-top: none;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template > .rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.template > .rightContainer > .div1 {
  flex: 0.5;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 4px 4px 8px 8px;
  border: 1px solid #a8a8a8;
  background: #fff;
}

.template > .rightContainer > .div1 > .numberCardContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.template > .rightContainer > .div2 {
  flex: 0.5;
  display: flex;
  background: #fff;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #a8a8a8;
}

.rightContainer > .div2 > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.rightContainer > .div2 > div:nth-child(1) {
  flex: 0.3;
}
.rightContainer > .div2 > div:nth-child(2) {
  flex: 0.9;
}

.rightContainer > .div2 > div:nth-child(3) {
  flex: 0.4;
}

.template > .rightContainer > .div3 {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.eqpsContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.eqpsContainer > div > .title {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.eqpsLeft {
  background: #fff;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 4px 4px 8px 8px;
  border: 1px solid #a8a8a8;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 5px;
}

.eqpsRight {
  background: #fff;
  box-shadow: 0px 2px 3px 1px #00000029;
  border-radius: 4px 4px 8px 8px;
  border: 1px solid #a8a8a8;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.eqpStatus {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.eqpStatus.run {
  border: 1px solid green;
  background-color: green;
}

.eqpStatus.stop {
  background-color: red;
  border: 1px solid red;
}

.eqpStatus.idle {
  background-color: orange;
  border: 1px solid orange;
}

.title {
  border-bottom: 1px solid #a8a8a8;
  height: 32px;
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.eqpContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: none;
}

.eqpContainer > div > .title {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.eqpList {
  flex: 1;
  display: flex;
  border-bottom: 1px solid #a8a8a8;
}

.eqpCard {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.eqpCard > .eqpCardtitle {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  flex: 1;
}

.eqpCard > .svgContainer {
  flex: 2.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.eqpCard > .svgContainer > div {
  background-color: black;
  width: 50%;
  height: 80%;
  /* width: 43px;
  height: 43px; */
  border-radius: 50%;
}

.svgContainer > .worstRank {
  position: absolute;
  top: 20%;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: rgb(220 220 0 / 100%);
  background-color: rgb(60 60 60 / 100%);
  border-radius: 50%;
}

.svgContainer > .cycle {
  position: absolute;
  top: 20%;
  left: 75%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: rgb(220 220 0 / 100%);
  background-color: rgb(60 60 60 / 100%);
  border-radius: 50%;
}

.eqpCard > .eqpCardValue {
  flex: 0.5;
  text-align: center;
  font-size: 12px;
}

@media screen and (max-width: 1700px) {
  .template {
    flex-direction: column;
  }
}
