.template {
  display: flex;
  height: 100%;
}

.template > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.template > div:nth-child(2) {
  margin-left: 10px;
}

.title {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.border {
  padding: 20px;
  border: 1px solid #f0f0f0;
  flex: 1;
  border-radius: 10px;
}
