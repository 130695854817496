.template {
  display: flex;
  height: 100%;
}

.template > .div1 {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.template > .div2 {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.template > div:nth-child(2) {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.border {
  border: 1px solid #f0f0f0;
  flex: 1;
  border-radius: 10px;
  background-color: #fafafa;
  /* overflow: scroll; */
}

.dtsetRow, .dtsetSelectedRow {
  cursor: pointer;
}

.dtsetSelectedRow {
  background-color: #dcd1ca;
}