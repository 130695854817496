.wrapper {
}

.chart div {
  cursor: crosshair !important;
}

.chart_drag_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.chart_drag_box {
  position: absolute;
  border: 1px solid #999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99;
  pointer-events: none;
}

.parameter_wrapper {
  display: flex;
}

.parameter_title {
  color: #8b8b8b;
  font-weight: 600;
  margin-right: 10px;
}

.parameter {
  display: flex;
  font-weight: 700;
}

.alarm {
  display: flex;
  margin-top: 10px;
  font-weight: 700;
}

.alarm_content {
  padding-right: 10px;
  border-right: 1px solid #ccc;
}

.alarm_facility {
  padding-left: 10px;
}

.value {
  display: flex;
  align-items: center;
  padding: 7px 0;
  color: #8b8b8b;
  font-weight: 600;
}

.top {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  margin-top: 20px;
}

.title {
  display: flex;
  font-weight: 500;
  align-items: center;
}

/* .title {
    display: flex;
    align-items: center;
    width: 70px;
    font-size: 14;
    font-weight: bold;
  } */

.circle {
  margin: auto 4px auto 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff0000;
}

.reset_zoom_icon {
  /* position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50px;
  height: 50px; */
  border-radius: 50%;
  color: white;
  background: linear-gradient(90deg, #9f2f6a 0%, #d24e49 100%);
  box-shadow: -5px 0px 5px -5px rgba(0, 0, 0, 0.75);
  transition: transform 0.2s;
  cursor: pointer;
}

.home {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
}

.reset_zoom:active {
  transform: scale(0.95);
}
