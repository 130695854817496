.template {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 10px;
}
