.errorLogTextarea {
  width: 100%;
  height: 1.4rem;
  border: 1px solid black;
  resize: none; /* 리사이즈 방지 */
}

.errorModalButtonAndTextContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
