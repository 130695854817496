.templete {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.chartSection {
  border: 1px solid #f0f0f0;
  background-color: #105780;
  border-radius: 8px;
  left: 50%;
  display: flex;
  height: 372px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: white;
  font-size: 80px;
}

.tableSection > .table {
  flex: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftChart {
  margin-left: 32px;
  margin-top: 12px;
}
.rightChart {
  position: relative;
  width: 100%;
}

.rightChart > div {
  display: flex;
}

.rightChart > .title {
  position: absolute;
  right: 100px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  letter-spacing: -0.22px;
  font-size: 22px;
  font-weight: 600;

  color: #fff;
}

.rightChart > .chart1 {
  position: absolute;
  top: 45px;
  right: 130px;
}

.rightChart > div > .chart2 {
  position: absolute;
  top: 190px;
  right: 220px;
}
.rightChart > div > .chart3 {
  position: absolute;
  top: 190px;
  right: 40px;
}

.tableTab {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 10px;
}

.tableSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}
