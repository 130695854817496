.search {
  padding: 10px;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search > .selects > div {
  margin: 3px 0px;
  margin-right: 10px;
}

.buttons > button:nth-child(1) {
  margin-right: 10px;
}
