.loginPage {
  display: flex;
  height: 100%;
  background-color: #e7ecf1;
  justify-content: center;
  align-content: center;
}

.loginForm {
  width: 420px;
  height: 420px;
  margin: auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 5px #b3b3b3;
}

.loginHeader {
  padding: 20px 0;
  text-align: center;
}

.loginDesc {
  width: 50%;
  margin: 12px auto;
  word-break: keep-all;
  color: #888888;
}