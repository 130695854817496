.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cSelect {
  margin-right: 12px;
  border-radius: 10px;
}

.cDatePicker {
  margin-right: 2px;
  border-radius: 10px;
}

.cTimePicker {
  margin-left: 2px;
  border-radius: 10px;
}
