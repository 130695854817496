.tagChartContainer {
  height: 100%;
}

.tag_trend_body {
  /* padding: 10px 5px;
  height: calc(100% - 100px);
  width: 100%;
  background-color: #e7ecf1 !important; */
  margin-top: 10px;
  background-color: #fff;
  height: calc(100vh - 200px);
  padding: 20px;
}

.head {
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
}

.tag_btn_area,
.run_btn_area {
  vertical-align: top;
  display: inline-block;
  height: 100%;
  /* margin-left: 10px; */
}

.tag_name_area {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 15px 5px 10px;
  background: white;
  border-radius: 5px;
  min-width: 225px;
}

.tag_name_area i {
  display: inline-block;
  visibility: hidden;
}

.tag_name_area:hover i {
  display: inline-block;
  cursor: pointer;
  visibility: visible;
}

.trend_tabs,
.trend_table {
  height: 100%;
  /* border: 1px solid red; */
}

.tag_trend_body > div > div:nth-child(2) > div,
.tag_trend_body > div > div:nth-child(2) > div > div {
  height: 100%;
}

.trend_chart {
  overflow-y: scroll;
  height: 100%;
}

/* 

app-tag-chart .head > div {
  height: 100%;
}

app-tag-chart
app-tag-chart .run-btn-area {
  text-align: right;
}

app-tag-chart .tag_name_area {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 15px 5px 10px;
  background: white;
  border-radius: 5px;
  min-width: 225px;
}
app-tag-chart .tag_name_area i {
  display: inline-block;
  visibility: hidden;
}
app-tag-chart .tag_name_area:hover i {
  display: inline-block;
  cursor: pointer;
  visibility: visible;
}

app-tag-chart nz-tabset .ant-tabs-content {
  height: 100%;
}
app-tag-chart .ng-star-inserted .ant-tabs-tabpane-active {
  height: 100%;
} */

.total-modeling-tree-container {
  height: calc(100% - 40px);
}

.total-modeling-tree-container-footer {
  height: 40px;
}

.tag-table {
  height: calc(100% - 50px);
  overflow: auto;
}

.spin-area,
.ant-spin-container {
  width: 100%;
  height: 100%;
}

app-tag-chart nz-progress {
  position: absolute;
  width: 100%;
  z-index: 10000;
  top: 75px;
}

.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

nz-input-group [nz-row] {
  width: 100%;
  margin: 5px;
}
nz-input-group [nz-row] > div:nth-child(1) {
  text-align: right;
}
nz-input-group [nz-row] > div:nth-child(1) span {
  padding-right: 5px;
}
.search_button {
  margin-left: 10px;
}
.tag-trend-modal-body {
  width: 100%;
  height: 100%;
  background: #e7ecf1;
}

.tag-chart-container .drawer-ok {
  margin-left: 8px;
}

.head .select {
  min-width: 195px;
}
