@value desktopLargeMediaQuery from '../../common/size.css';

.template {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.div1 {
  flex: 1;
  display: flex;
  margin-bottom: 10px;
  max-height: 250px;
}

.div2 {
  flex: 3;
  display: flex;
}

.processsumContainer {
  flex: 3;
  margin-right: 10px;
  display: flex;
}

.processsumContainer > div {
  margin-right: 5px;
}

.processsumContainer > div:nth-last-child(1) {
  margin-right: 0px;
}

.processsum {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 2px solid #0487d9;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  background: #00021d;
}

.processsum > .title {
  flex: 0.8;
  text-align: center;
  text-decoration: underline;
  color: #0487d9;
}

.processsum > .power {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.processsum > .power > div {
  flex: 1;
  display: flex;
  padding-left: 15px;
  color: #fff;
}

.emissionContainer {
  flex: 2;
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
}

.emissionContainer > .circle:nth-last-child(1) {
  margin-right: 0px !important;
}

.emissionContainer > .circle {
  flex: 1;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle.pink {
  background: #ff316c;
  margin-right: 10px;
}
.circle.blue {
  background: #0086ff;
  margin-right: 10px;
}
.circle.green {
  background: #15b200;
}

.emissionContainer > .circle > div {
  color: #fff;
}

.sumContainer {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}

.sumComponent {
  flex: 1;
  display: flex;
  margin-bottom: 5px;
}

.sumComponent > .div1,
.sumComponent > .div2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 5px;
  margin-bottom: 0px;
  border-radius: 10px;

  background: #0487d9;
}
.sumComponent > .div2 {
  margin-right: 0px !important;
}

.sumComponent > .div1 > div:nth-child(2),
.sumComponent > .div2 > div:nth-child(2) {
  font-size: 30px;
}

.sumComponent > .div1 > div:nth-child(2) > span {
  font-size: 15px;
}

.emissionDiv {
  flex: 1;
  display: flex;
}

.emissionDiv > div {
  border-radius: 10px;
  flex: 1;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: green;
}

.emissionDiv > div > div > span {
  font-size: 15px !important;
}
.emissionDiv > div > div {
  font-size: 24px !important;
}

.emissionDiv > div:nth-last-child(1) {
  margin-right: 0px !important;
}

.div2 > .processContainer {
  flex: 3;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.processContainer > .row1 {
  flex: 1;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
}

.processContainer > .row1:nth-last-child(1) {
  margin-bottom: 0px !important;
}

.processContainer > .row1 > div {
  flex: 1;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  background-color: #00021d;
  border-radius: 10px;
  border: 2px solid #0487d9;
  color: #fff;
}

.processContainer > .row1 > div:nth-last-child(1) {
  margin-right: 0px !important;
}

.processContainer > .row1 > div > .title {
  height: 30px;
  padding-top: 3px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 3px;
  border-bottom: 1px solid #a8a8a8;
}

.eqpList {
  flex: 1;
  display: flex;
  /* border-bottom: 1px solid #a8a8a8; */
}

.eqpCard {
  flex: 1;
  display: flex;
  padding: 3px;
  color: #fff !important;
}

.eqpCard > div {
  padding: 1px;
  border-radius: 5px;
  display: flex;
  flex: 1;
}

.svgContainer {
  flex: 1.5;
  display: flex;
  flex-direction: column;
}

.svg {
  min-width: 50px;
  min-height: 43px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.eqpCardhover:hover {
  cursor: pointer;
  transform: scale(1.02);
  background: #343e40 !important;
  overflow: hidden;
}

.eqpCardtitle {
  max-height: 30px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eqpCardtitle,
.eqpCardValue {
  font-size: 7px;
  font-weight: bold;
}
.eqpCardValue > span {
  font-size: 4px !important;
}

.values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1.5;
  margin-left: 3px;
}

.chartContainer {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  background-color: #00021d;
  border-radius: 10px;
  border: 2px solid #0487d9;
}

@media screen and (min-width: 2000px) {
  .eqpCardtitle,
  .eqpCardValue {
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
  }

  .unloadTitle,
  .unloadCardValue {
    font-size: 18px !important;
    font-weight: bold;
  }
}

@media screen and (max-width: 1200px) {
  .div2 {
    display: flex;
    flex-direction: column;
  }
  .processContainer {
    margin-right: 0px !important;
  }
  .processContainer > .row1 {
    display: flex;
    flex-direction: column;
  }
  .processContainer > .row1 > div {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .chartContainer {
    display: flex;
    flex-direction: row;
  }
}

.hover {
  transition: all 300ms ease;
}

.hover:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: all 300ms ease;
  background: #00008b !important;
}

/* process sum  */
.processsum > .unload {
  display: flex;
  flex: 1;
  padding: 10px;
}
.processsum > .unload > div {
  flex: 1;
}

.processsum > .unload > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #0487d9;
}

.processsum > .unload > div:nth-child(1) > .title {
  text-align: center;
  text-decoration: underline;
  color: #0487d9;
}

.processsum > .unload > div:nth-child(1) > .unloadSvg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.processsum > .unload > div:nth-child(2) {
  color: #fff;
  display: flex;
  flex-direction: column;
}

.processsum > .unload > div:nth-child(2) > .power {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.processsum > .unload > div:nth-child(2) > .emissionContainer {
  display: flex;
  padding: 20px 0px 20px 10px;
}

.eqpCard > .unload > .svgContainer {
  display: flex;
}

.eqpCard > .unload > .values,
.eqpCard > .unload > .svgContainer > .unloadTitle {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-start;
  /* border: 1px solid red; */
}
.eqpCard > .unload > .svgContainer > .unloadTitle {
  height: 40px;
}

.eqpCard > .unload > .svgContainer > .unloadTitle {
  font-size: 18px;
}

.eqpCard > .unload > .values > .unloadCardValue {
  font-size: 14px;
}
