.content > ul {
  font-size: 14px;
  font-weight: 700;
}

.content > ul > li > span:nth-child(1) {
  width: 90px;
  display: inline-flex;
}

.content > ul > li > span:nth-child(2) {
  margin-left: 5px;
}
