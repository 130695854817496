.templete {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chartSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chartSection > div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0px;
  background-color: #105780;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
