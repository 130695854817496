.template {
  display: flex;
  flex-direction: column;
  height: 906px;
}

.title {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.28px;
  color: #f0f0f0;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.title > div:nth-child(1) {
  flex: 1;
  text-align: center;
}

.chart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chart > div {
  flex: 1 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid wheat; */
}

.hover:hover {
  cursor: pointer;
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.28px;
  color: #f0f0f0;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.loading {
  color: white;
  font-size: 100px;
}
