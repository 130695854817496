/* 기본 레이아웃 start */
* {
  /*font-family: "Noto Sans KR", sans-serif;*/
  box-sizing: border-box;
  outline: none;
  border: none;
}

#root {
  width: 100%;
  height: 100vh;
}

section {
  width: 100%;
  height: 100vh;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0px;
}

#default-layout {
  height: 100vh;
}

#site-layout {
  background-color: #e7ecf1;
}

#site-content {
  padding: 24px;
  overflow-y: auto;
}

#site-footer {
  color: #bbb;
  display: flex;
  justify-content: flex-end;
  margin: 0 5px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 16px 0;
}

#header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);

}

.header-left {
  display: flex;
  align-items: center;
}

#header .header-right {
  display: flex;
  padding-right: 30px;
  cursor: pointer;
}

#header .header-right > div:nth-child(2) {
  margin-left: 20px;
}

.menu-toggle {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.menu-toggle .ant-btn {
  height: 50px;
  width: 50px;
}

.header-bottom {
  padding: 12px 0 0 24px;
}

.breadcrumb ol {
  display: flex;
  align-items: center;
}

.breadcrumb-last {
  font-size: 16px;
  font-weight: bold;
}

/* 기본 레이아웃 end */

/* 테이블 start */

.ant-table .ant-table-thead > tr > th {
  word-break: keep-all;
  text-align: center !important;
}

.ant-table .ant-table-tbody {
  background-color: #fff;
}

.ant-dropdown-trigger.ant-table-filter-trigger,
.ant-dropdown-trigger.ant-table-filter-trigger.active {
  color: #000;
}

.importing-color {
  background: red;
}

/* .ant-table .ant-table-tbody > tr > td {
} */
/* 테이블 start */

/* 탭 start */

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border: none;
}

.ant-tabs-nav {
  border-bottom: 1px solid rgba(22, 119, 255, 0.5);
}

.ant-tabs-tab {
  background-color: #fff !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  color: #fff !important;
  background-color: #1677ff !important;
}

.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #fff !important;
}

/*  */
.tableTab .ant-tabs-nav {
  margin: 0px;
}

/* carbon tab*/
#carbon.ant-tabs.ant-tabs-top.ant-tabs-card {
  height: 100%;
  display: flex;
}

#carbon .ant-tabs-content-holder {
  flex: 1;
  display: flex;
}

#carbon .ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}

/* inner */
#inner .ant-tabs-tab {
  background-color: #f3f3f3 !important;
  color: #000000a6 !important;
}

#inner .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #1677ff !important;
}

#inner .ant-tabs-content-holder,
#inner .ant-tabs-content.ant-tabs-content-top,
#inner .ant-tabs.ant-tabs-top.ant-tabs-small.ant-tabs-card,
#inner {
  display: flex;
  flex: 1;
}

#inner .ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: flex;
  flex: 1;
}

#inner .ant-tabs-tabpane.ant-tabs-tabpane-active > div {
  flex: 1;
  display: flex;
}

/* 탭 end */

/* 모달 start */
.common .ant-modal-header {
  padding: 0px !important;
  margin: 0px !important;
  border-bottom: 1px solid #00a8e7;
}

.common .ant-modal-title {
  font-size: 18px !important;
  color: #00a8e7 !important;
}

.common .ant-modal-body {
  margin: 40px 30px;
}

.common .ant-modal-footer {
  border-top: 1px solid #e8e8e8;
  padding-top: 12px;
  margin-top: 0px;
}

.ant-modal-header {
  height: 32px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

/* cycle modal */

.cycle .ant-modal {
  padding: 20px;
}

.cycle .ant-modal-header {
  background-color: #1f295a !important;
  border-bottom: 1px solid #98f7f3 !important;
}

.cycle .ant-modal-title {
  color: #98f7f3;
  font-weight: bold;
}

.cycle .ant-modal-close-x {
  padding-right: 40px;
  color: #ffffff;
}

.cycle .ant-modal-body {
  padding: 30px 20px;
}

.cycle .ant-modal-content {
  background-color: #1f295a !important;
}

.cycle .ant-col.ant-form-item-label > label {
  color: #ffffff;
  font-weight: bold;
}

.cycle .ant-form-item-control-input-content > input {
  background-color: transparent;
  border: 1px solid #98f7f3;
  color: #ffffff;
}

.cycle .ant-picker {
  background-color: transparent;
  border: 1px solid #98f7f3;
}

.cycle .ant-picker-input > input,
.cycle .ant-picker-input > input::placeholder {
  color: #ffffff;
}

.cycle .ant-picker-input > .ant-picker-suffix {
  color: #98f7f3;
}

/* flow modal */
.flow .ant-modal-header,
.flow .ant-modal-content {
  background-color: #1f295a;
  opacity: 0.9;
}

.flow .ant-modal-title {
  padding: 20px;
  color: #98f7f3;
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  padding-right: 50px;
}

.flow .ant-modal-close-x {
  padding-top: 25px;
  color: #ffffff;
  font-size: 24px;
}

/* svg path {
  animation: dash 80s 0s infinite linear;
} */

@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -1000;
  }
}

/* 모달 end */

/* drawer */
.ant-drawer-header {
  border-bottom: 1px solid lightgray !important;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
}

/* dark css */

.charModal .ant-modal-content {
  background: #1b1e46;
}

/* apexchart */
.donut .apexcharts-tooltip {
  color: #000 !important;
  font-weight: bold;
}

iframe {
  display: none;
}

/*.dataShare .ant-table-tbody > tr > td {*/
/*  height: 5px !important;*/
/*  padding: 4px !important;*/
/*}*/

/* 설비 건강도 정보의 align-items 스타일 속성을 기본값으로 변경  */
.search-component-health-search,
.ant-radio-wrapper.css-dev-only-do-not-override-htwhyh {
  align-items: normal;
}

.noxTable .ant-table-header .ant-table-thead {
  height: 100px !important;
}

.selected-row {
  background-color: #f5f5f5; /* antd 테이블 row 클릭 시 변경되는 색상 */
}

/* oms dashboard */

/* DESCRIPTION(설비가동현황) */
.opertaioappnStatus {
  color: #fff !important;
}

.opertaionStatus .ant-descriptions-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  border: 1px solid;
  font-size: 1.3vh;
  margin-bottom: 10px;
  background-color: #0a355b !important;
}

.ant-descriptions-item-content span.greenText {
  color: #57ff57 !important;
  font-weight: 500;
}

.opertaionStatus .ant-descriptions-view {
  margin: 10px 20px;
  width: 90%;
}

.opertaionStatus .ant-descriptions-row {
  background-color: #28435c;
  color: #fff;
}

.opertaionStatus .ant-descriptions {
  text-align: center;
}

.opertaionStatus > .ant-descriptions-item-label,
.opertaionStatus > .ant-descriptions-item-content {
  background: none !important;
  color: #fff;
  font-size: 12.5px;
  white-space: pre-wrap;
  text-align: center;
}

.opertaionStatus > .ant-table-thead > tr > th {
  color: #fff;
  background: none;
}

.ant-descriptions.ant-descriptions-bordered .ant-descriptions-row {
}

/* dashboard table */
.darkblueTable .ant-table.ant-table-small.ant-table-bordered {
  border-radius: 0px !important;
}

.darkblueTable tr th,
.darkblueTable td {
  background-color: #0a355b !important;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
}

.darkblueTable .ant-table-thead > tr > th {
  height: 4px !important;
  padding: 1px !important;
}

.darkblueTable .ant-table-tbody > tr > td {
  height: 4px !important;
  padding: 1px !important;
}

/* script modal start */
.flowAlgo .ant-modal-content,
.flowAlgo .ant-modal-header {
  background-color: rgba(60, 60, 60, 1);
  border-bottom: 1px solid #1890ff;
}

.flowAlgo .ant-modal-header .ant-modal-title > div {
  display: flex;
  justify-content: space-between;
}

.flowAlgo .ant-modal-body {
  padding-bottom: 0px;
  background-color: rgba(60, 60, 60, 1);
}

.flowAlgo .ant-modal-footer {
  border: 0;
  background-color: rgba(60, 60, 60, 1);
}

.flowAlgo .ant-modal-close-x {
  color: white;
}

.flowchart .ant-modal-header {
  background-color: transparent;
}

.flowchart .ant-modal-content {
  backdrop-filter: blur(1.5px) saturate(167%);
  background-color: rgba(255, 2, 2, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.725);
}

.flowchart .ant-modal-footer {
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.tableModal .ant-modal-header {
  background-color: rgba(155, 155, 155, 0.85);
}

.tableModal .ant-modal-title {
  color: #333 !important;
}

.tableModal .ant-modal-title {
  color: #333 !important;
}

.tableModal .ant-modal-header {
  background-color: transparent;
  border-bottom: 1px solid rgb(173, 168, 168);
}

.tableModal .ant-modal-content {
  backdrop-filter: blur(1.5px) saturate(167%);
  background-color: rgba(155, 155, 155, 0.85);
  border: 1px solid rgba(255, 255, 255, 0.725);
}

.tableModal .ant-modal-footer {
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.tableModal .font {
  color: #faffde !important;
}

button.tableModal {
  background-color: rgb(75, 73, 73) !important;
  border: 1px solid #fff !important;
}

button.tableModal:hover {
  background-color: #fff !important;
  color: rgb(75, 73, 73) !important;
  border: 1px solid rgb(75, 73, 73) !important;
}

.tree-upper {
  width: 33%;
}